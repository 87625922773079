<template>
  <div class="notfound-container">
    <div class="notfound-card">
      <h3>Page Not Found</h3>
      <p>
        Sorry, please check the address entered or return
        <router-link to='/'>home</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Not Found',
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.notfound-container {
  display: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  .notfound-card {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 70%;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
  }

  @media only screen and (min-width: $sm) {
    .notfound-card {
      width: 30%;
    }
  }
}
</style>
